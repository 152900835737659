import { accordionTheme } from './components/Accordion/theme';
import { alertTheme } from './components/Alert/theme';
import { avatarTheme } from './components/Avatar/theme';
import { badgeTheme } from './components/Badge/theme';
import { breadcrumbTheme } from './components/Breadcrumb/theme';
import { buttonGroupTheme, buttonTheme } from './components/Button/theme';
import { cardTheme } from './components/Card/theme';
import { carouselTheme } from './components/Carousel/theme';
import { checkboxTheme } from './components/Checkbox/theme';
import { darkThemeToggleTheme } from './components/DarkThemeToggle/theme';
import { dropdownTheme } from './components/Dropdown/theme';
import { fileInputTheme } from './components/FileInput/theme';
import { footerTheme } from './components/Footer/theme';
import { helperTextTheme } from './components/HelperText/theme';
import { labelTheme } from './components/Label/theme';
import { listGroupTheme } from './components/ListGroup/theme';
import { modalTheme } from './components/Modal/theme';
import { navbarTheme } from './components/Navbar/theme';
import { paginationTheme } from './components/Pagination/theme';
import { progressTheme } from './components/Progress/theme';
import { radioTheme } from './components/Radio/theme';
import { rangeSliderTheme } from './components/RangeSlider/theme';
import { ratingTheme } from './components/Rating/theme';
import { selectTheme } from './components/Select/theme';
import { sidebarTheme } from './components/Sidebar/theme';
import { spinnerTheme } from './components/Spinner/theme';
import { tabTheme } from './components/Tab/theme';
import { tableTheme } from './components/Table/theme';
import { textInputTheme } from './components/TextInput/theme';
import { textareaTheme } from './components/Textarea/theme';
import { timelineTheme } from './components/Timeline/theme';
import { toastTheme } from './components/Toast/theme';
import { toggleSwitchTheme } from './components/ToggleSwitch/theme';
import { tooltipTheme } from './components/Tooltip/theme';
export const theme = {
    accordion: accordionTheme,
    alert: alertTheme,
    avatar: avatarTheme,
    badge: badgeTheme,
    breadcrumb: breadcrumbTheme,
    button: buttonTheme,
    buttonGroup: buttonGroupTheme,
    card: cardTheme,
    carousel: carouselTheme,
    checkbox: checkboxTheme,
    darkThemeToggle: darkThemeToggleTheme,
    dropdown: dropdownTheme,
    fileInput: fileInputTheme,
    footer: footerTheme,
    helperText: helperTextTheme,
    label: labelTheme,
    listGroup: listGroupTheme,
    modal: modalTheme,
    navbar: navbarTheme,
    pagination: paginationTheme,
    progress: progressTheme,
    radio: radioTheme,
    rangeSlider: rangeSliderTheme,
    rating: ratingTheme,
    select: selectTheme,
    textInput: textInputTheme,
    textarea: textareaTheme,
    toggleSwitch: toggleSwitchTheme,
    sidebar: sidebarTheme,
    spinner: spinnerTheme,
    tab: tabTheme,
    table: tableTheme,
    timeline: timelineTheme,
    toast: toastTheme,
    tooltip: tooltipTheme,
};
