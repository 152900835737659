import { Modal } from "flowbite-react";
import React from "react";

export function LegalModal(props: { show: boolean, onClose: () => void }) {
  return <Modal show={props.show} dismissible onClose={props.onClose}>
    <Modal.Header>Legal Information</Modal.Header>
    <Modal.Body>
      <p>The <a href="https://www.yes23.com.au/privacy-policy" target="_blank" className="underline">Privay Policy</a> and <a href="https://www.yes23.com.au/terms-conditions" target="_blank" className="underline">Terms of Service</a> of Yes23.com.au apply to this website.</p>

      <p>Please note that no photographic data is captured as a result of you using this site. All images are processed in your browser.</p>
    </Modal.Body>
  </Modal>
}
