import { jsx as _jsx } from "react/jsx-runtime";
import { twMerge } from 'tailwind-merge';
import { useTheme } from '../../';
import { mergeDeep } from '../../helpers/merge-deep';
import { useTimelineContext } from './TimelineContext';
export const TimelineItem = ({ children, className, theme: customTheme = {}, ...props }) => {
    const theme = mergeDeep(useTheme().theme.timeline.item, customTheme);
    const { horizontal } = useTimelineContext();
    return (_jsx("li", { "data-testid": "timeline-item", className: twMerge(horizontal && theme.root.horizontal, !horizontal && theme.root.vertical, className), ...props, children: children }));
};
