import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { HiMoon, HiSun } from 'react-icons/hi';
import { twMerge } from 'tailwind-merge';
import { useTheme } from '../../';
import { ThemeContext } from '../../components/Flowbite/ThemeContext';
import { mergeDeep } from '../../helpers/merge-deep';
export const DarkThemeToggle = ({ className, theme: customTheme = {}, iconDark: IconDark = HiSun, iconLight: IconLight = HiMoon, ...props }) => {
    const theme = mergeDeep(useTheme().theme.darkThemeToggle, customTheme);
    const { mode, toggleMode } = useContext(ThemeContext);
    return (_jsx("button", { "aria-label": "Toggle dark mode", "data-testid": "dark-theme-toggle", onClick: toggleMode, type: "button", className: twMerge(theme.root.base, className), ...props, children: mode === 'dark' ? (_jsx(IconLight, { "aria-label": "Currently dark mode", className: theme.root.icon })) : (_jsx(IconDark, { "aria-label": "Currently light mode", className: theme.root.icon })) }));
};
DarkThemeToggle.displayName = 'DarkThemeToggle';
