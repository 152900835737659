import React from "react";
import { Route, Routes } from "react-router-dom";
import * as app from "./exports";

export function AppRouter() {
  return (
    <Routes>
      <Route path={app.homeUrl} element={<app.HomePage />}></Route>
      <Route path={app.uploadImageUrl} element={<app.UploadImagePage />}></Route>
      <Route path={app.downloadUrl} element={<app.DownloadPage />}></Route>
      <Route path={app.shareUrl} element={<app.SharePage />}></Route>
      <Route path="*" element={<app.Error404Page link={app.homeUrl} />}></Route>
    </Routes>
  )
}
