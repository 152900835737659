import { Button } from "flowbite-react";
import CameraIcon from "jsx:../svg/Camera.svg";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ImgContext, PageWrapper, PhotoBooth, downloadUrl, homeUrl, recordEvent } from "../exports";


export function UploadImagePage() {
  const img = useContext(ImgContext);
  const navigate = useNavigate();
  const [showPhotoBooth, setShowPhotoBooth] = useState(false);
  const [percentComplete, setPercentComplete] = useState(25);
  const [isProcessing, setIsProcessing] = useState(false);

  const loadingGif = useCallback(() => {
    return <img src={require("../images/loading.gif")} alt="loading" className="h-36 w-36" />
  }, []);

  function incPercentComplete() {
    setPercentComplete((prev) => {
      if (prev <= 89) {
        return prev + 10;
      } else {
        return 90;
      }
    });
  }

  useEffect(() => {
    if (percentComplete >= 90) {
      navigate(downloadUrl);
    }
  }, [percentComplete]);

  useEffect(() => {
    if (!isProcessing) return;
    const unsub = setInterval(incPercentComplete, 200);
    return () => clearInterval(unsub);
  }, [isProcessing]);

  useEffect(() => {
    if (img.hasUploaded && !isProcessing) {
      setIsProcessing(true);
    }
  }, [img.hasUploaded]);

  useEffect(() => {
    // Clear out the old image.
    img.clear();
    setIsProcessing(false);
    setPercentComplete(25);
  }, []);

  return <PageWrapper backButtonUrl={homeUrl} percentComplete={percentComplete}>
    <div className="flex flex-col h-full w-full py-2 px-7 relative">
      {isProcessing &&
        <div className="flex flex-col h-2/5 justify-center items-center">
          {loadingGif()}
        </div>
      }
      {!isProcessing && <><h1 className="text-2xl">
        <div className="flex flex-row items-center">
          Capture Your Selfie
          <img src={require("../images/selfie.png")} className="w-8 ml-1 inline" />
        </div>
      </h1>
        <p className="text-xs mt-3 mb-8 space-y-1.5 font-light">
          <span className="font-bold">Hot Tip:</span>&nbsp;
          <span className="text-gray-400 font-light">take your photo in a bright spot &amp; make sure your face is centered!</span>
        </p>

        <Button color="gray" className="upload-wrapper text-primary" pill>
          <CameraIcon className="mr-2 h-6" />
          Upload a Photo
          <input type="file" name="upload" onChange={(event) => {
            if (event.target.files && event.target.files[0]) {
              recordEvent("uploadImg");
              img.uploadImage(event.target.files[0]).then(() => {
                setIsProcessing(true);
              });
            }
          }} />
        </Button>

        <div className="flex items-center my-3">
          <div className="w-full h-0.5 bg-gray-200 dark:bg-gray-700"></div>
          <div className="px-5 text-center text-gray-500 dark:text-gray-400 font-bold text-lg">or</div>
          <div className="w-full h-0.5 bg-gray-200 dark:bg-gray-700"></div>
        </div>

        <button className="flex flex-row w-full cursor-pointer mt-2 pb-5" onClick={() => {
          recordEvent("showPhotoBooth");
          setShowPhotoBooth(true);
        }}>
          <img src={require("../images/photobooth-button.png")} alt="upload" className="w-full h-full" />
        </button>
      </>}
    </div>
    <PhotoBooth show={showPhotoBooth} onClose={() => setShowPhotoBooth(false)} onTakeImage={() => setIsProcessing(true)} />
  </PageWrapper>
}
