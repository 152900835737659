import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBSMO8la1f1OpPVxgfopCzBrIUsGXOpN3c",
  authDomain: "yes23-selfie-frame-generator.firebaseapp.com",
  projectId: "yes23-selfie-frame-generator",
  storageBucket: "yes23-selfie-frame-generator.appspot.com",
  messagingSenderId: "373223328271",
  appId: "1:373223328271:web:74def39ad5d1f2c5e0317a",
  measurementId: "G-PZDQJP8CJ3"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
