import InApp from "detect-inapp";
import { ThemeProps } from "flowbite-react";

export const isProd = process.env.NODE_ENV === "production";
export const inApp = new InApp(navigator.userAgent);

export const baseUrl = "https://imvoting.yes23.com.au";

export const homeUrl = "/";
export const uploadImageUrl = "/upload";
export const downloadUrl = "/download";
export const shareUrl = "/share";

export const primaryColor = "#702A79";
export const teriaryColor = "#76B9BB";

export const exampleTweet = `I just created my own profile photo supporting the Voice to Parliament. Generate your own here: ${baseUrl} #yes23`;

export const flowbiteTheme: ThemeProps = {
  theme: {
    button: {
      color: {
        primary: "text-white bg-primary border border-transparent enabled:hover:bg-primary-600 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:enabled:hover:bg-primary-700 dark:focus:ring-primary-900",
        primaryAlt: "text-primary bg-primaryAlt border border-transparent enabled:hover:bg-teriary-600 focus:ring-4 focus:ring-teriary-300 dark:bg-teriary-600 dark:enabled:hover:bg-teriary-700 dark:focus:ring-teriary-900",
        secondary: "text-black bg-secondary border border-transparent enabled:hover:bg-yellow-300 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:enabled:hover:bg-primary-700 dark:focus:ring-primary-900",
        gray: "text-black bg-grayButton border border-transparent enabled:hover:bg-yellow-300 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:enabled:hover:bg-primary-700 dark:focus:ring-primary-900",
      }
    },
    progress: {
      color: {
        primary: "bg-primary dark:bg-primary-600",
      }
    },
    spinner: {
      color: {
        primary: "text-primary dark:text-primary-600",
      }
    }

  }
};
