import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { recordPageView } from "../../utils/analytics";

export function AnalyticsObserver() {
  const location = useLocation();

  useEffect(() => {
    recordPageView(location.pathname)
  }, [location]);

  return null;
}
