import { Button, Spinner } from "flowbite-react";
import ClipboardIcon from "jsx:../svg/Clipboard.svg";
import FbIcon from "jsx:../svg/Facebook.svg";
import InstaIcon from "jsx:../svg/Instagram.svg";
import TwitterIcon from "jsx:../svg/Twitter.svg";
import React, { ComponentType, SVGProps, useContext, useEffect, useState } from "react";
import Confetti from 'react-confetti';
import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import useClipboard from "react-use-clipboard";
import { ImgContext, PageWrapper, recordEvent, uploadImageUrl } from "../exports";

interface Social {
  url: string;
  icon: ComponentType<SVGProps<SVGSVGElement>>;
  eventId?: string;
}

const socials: Social[] = [
  { url: "https://www.facebook.com/yes23au", icon: FbIcon, eventId: "fbProfileClick" },
  { url: "https://www.instagram.com/yes23au/", icon: InstaIcon, eventId: "instaProfileClick" },
  { url: "https://twitter.com/yes23au", icon: TwitterIcon, eventId: "twitterProfileClick" },
];

const captionPrefix = "I'll be voting YES!"
const captionText = "Because I want to live in a country that recognises 65,000 years of Indigenous culture in our constitution. Listening through a Voice means we'll work together to create a better future. Want this selfie? https://imvoting.yes23.com.au #VoteYES #yes23 @yes23au"
const caption = captionPrefix + " " + captionText;

export function DownloadPage() {
  const navigate = useNavigate();
  const img = useContext(ImgContext);
  const [imgSrc, setImgSrc] = useState("");
  const [isCopied, setCopied] = useClipboard(caption, { successDuration: 2000 });

  useEffect(() => {
    if (!img.hasGenerated) {
      navigate(uploadImageUrl);
    } else {
      setImgSrc(img.getBase64());
    }
  }, []);

  return <PageWrapper backButtonUrl={uploadImageUrl} percentComplete={90}>
    <Confetti numberOfPieces={200} recycle={false} colors={["#672E74", "#85B6B8", "#F5D848", "#EEB154", "#BC9B72", "#C44234", "#EAB1C4", "#DE6438"]} />

    <div className="flex flex-col items-center h-full w-full py-2 px-7 relative">
      <h2 className="text-center">Download your photo!</h2>

      <div className="text-primary text-sm mt-4 font-bold">
        Step 1
      </div>

      {isMobile ?
        <div className="mb-4 text-center">
          <span className="underline font-bold">Long press on the image</span> and<br /><span className="underline font-bold">Select Save</span>
        </div>
        :
        <div className="mb-4">
          <span className="underline font-bold">Right click</span> to save your photo
        </div>
      }

      <img src={require("../images/arrow2.png")} className="animate-melt absolute top-[55px] left-[50px] max-h-[70px]" />

      {imgSrc ? <img src={imgSrc} className="mb-3 aspect-square w-3/5 rounded-xl shadow-sm"
        onClick={() => recordEvent("savePhoto")}
        onContextMenu={() => recordEvent("savePhoto")}
        onTouchEndCapture={() => recordEvent("savePhoto")}
        title={isMobile ? "Tap and hold to save" : "Right click to save"}
        alt="My Yes23 photo"
      />
        :
        <Spinner />}

      <div className="text-primary text-sm mt-4 mb-2 font-bold">
        Step 2
      </div>

      <Button pill color="primaryAlt" onClick={() => {
        recordEvent("copyCaption");
        setCopied();
      }}>
        <ClipboardIcon className="mt-[3px] mr-2" />
        {isCopied ? "Copied!" : "Tap to copy caption"}
      </Button>

      <div className="text-primary text-sm mt-6 mb-2 font-bold">
        Step 3
      </div>

      <div className="w-3/5 text-center -space-y-4">
        <span className="font-bold">Paste</span> the <span className="font-bold">caption</span> when creating
        your social post.
      </div>

      <div className="pb-3">
        <div className="text-primary text-lg font-bold mt-6">Caption for your social post</div>
        <div className="flex flex-row mt-3 my-2">
          <div className="flex flex-col w-4 bg-primary rounded-full">&nbsp;</div>
          <div className="p-3 italic text-primary-400 whitespace-pre-wrap">
            <b>{captionPrefix}</b> {captionText}
          </div>
        </div>
      </div>

      <div className="flex-flex-col w-full text-center pb-16">
        <div className="text-primary text-lg font-bold mt-3 mb-4">Follow @Yes23au</div>
        {socials.map((social, i) => <a key={social.url} href={social.url} target="_blank" onClick={() => recordEvent(social.eventId)}>
          {<social.icon className="h-10 mx-2 inline-block" />}
        </a>)}
      </div>
    </div>
  </PageWrapper >
}
