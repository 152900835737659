import { Flowbite } from "flowbite-react";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AnalyticsObserver, AppRouter, ImgContext, flowbiteTheme, img } from "./exports";

const container = document.getElementById("app") as HTMLElement;
const root = createRoot(container)
root.render(
  <ImgContext.Provider value={img}>
    <BrowserRouter>
      <AnalyticsObserver />
      <Flowbite theme={flowbiteTheme}>
        <AppRouter />
      </Flowbite>
    </BrowserRouter>
  </ImgContext.Provider>
);
