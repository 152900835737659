import { Button, TextInput } from "flowbite-react";
import Clipboard from "jsx:../svg/Clipboard.svg";
import FbIcon from "jsx:../svg/Facebook.svg";
import InstaIcon from "jsx:../svg/Instagram.svg";
import TwitterIcon from "jsx:../svg/Twitter.svg";
import React, { ComponentType, SVGProps, useContext } from "react";
import useClipboard from "react-use-clipboard";
import { ImgContext, PageWrapper, baseUrl, downloadUrl, exampleTweet, recordEvent } from "../exports";


interface Social {
  url: string;
  icon: ComponentType<SVGProps<SVGSVGElement>>;
  eventId?: string;
}

const socials: Social[] = [
  { url: "https://www.facebook.com/yes23au", icon: FbIcon, eventId: "fbProfileClick" },
  { url: "https://www.instagram.com/yes23au/", icon: InstaIcon, eventId: "instaProfileClick" },
  { url: "https://twitter.com/yes23au", icon: TwitterIcon, eventId: "twitterProfileClick" },
];

export function SharePage() {
  const img = useContext(ImgContext);
  const [isCopied, setCopied] = useClipboard(baseUrl, { successDuration: 2000 });

  return <PageWrapper percentComplete={100} backButtonUrl={downloadUrl}>
    <div className="flex flex-col h-full w-full py-2 px-7">
      <h1 className="text-2xl">
        <div className="flex flex-row items-center">
          Share with 3 friends
        </div>
      </h1>
      <p className="text-sm mt-6 ">
        <b>You're a trusted messenger to your friends, family and community.</b>
      </p>
      <p className="text-sm my-2">If you can convince 3 people to vote YES with you, we can do this!</p>
      <p className="text-sm">Share why you are voting yes! 👇</p>

      <div className="flex flex-col gap-4 my-7">
        <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(baseUrl)}`} target="_blank" onClick={() => recordEvent("fbShare")}>
          <Button color="secondary" pill fullSized >
            Share on Facebook
          </Button>
        </a>

        <a href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(exampleTweet)}`} target="_blank" onClick={() => recordEvent("twitterShare")}>
          <Button color="secondary" pill fullSized >
            Share on Twitter
          </Button>
        </a>

        <p>To <b>share on Instagram</b>, download your image from the previous page and upload into the Insta app.</p>
      </div>

      <div className="mt-5 text-center">
        <div className="text-primary text-lg font-bold mb-4">Send a link to your friends</div>
        <div className="flex flex-row gap-2 w-full">
          <TextInput readOnly value={baseUrl} className="flex flex-grow" />
          <Button sizing="sm" pill color="primaryAlt" onClick={() => {
            recordEvent("copyWebsiteUrlToShare");
            setCopied();
          }}>
            <Clipboard className="mt-[3px] mr-2" />
            {isCopied ? "Copied!" : "Copy link"}
          </Button>
        </div>

        <div className="text-primary text-lg font-bold mt-8 mb-4">Follow @Yes23au</div>
        {socials.map((social, i) => <a key={social.url} href={social.url} target="_blank" onClick={() => recordEvent(social.eventId)}>
          {<social.icon className="h-10 mx-2 inline-block" />}
        </a>)}
      </div>
    </div>
  </PageWrapper>
}
