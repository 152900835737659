import { jsx as _jsx } from "react/jsx-runtime";
import { twMerge } from 'tailwind-merge';
import { useTheme } from '../../';
import { mergeDeep } from '../../helpers/merge-deep';
import { ListGroupItem } from './ListGroupItem';
const ListGroupComponent = ({ children, className, theme: customTheme = {}, ...props }) => {
    const theme = mergeDeep(useTheme().theme.listGroup, customTheme);
    return (_jsx("ul", { className: twMerge(theme.root.base, className), ...props, children: children }));
};
ListGroupComponent.displayName = 'ListGroup';
ListGroupItem.displayName = 'ListGroup.Item';
export const ListGroup = Object.assign(ListGroupComponent, { Item: ListGroupItem });
