import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { theme as defaultTheme } from '../../';
import { mergeDeep } from '../../helpers/merge-deep';
import { ThemeContext, useTheme, useThemeMode } from './ThemeContext';
export const Flowbite = ({ children, theme = {} }) => {
    const { theme: customTheme = {}, dark } = theme;
    const [mode, setMode, toggleMode] = useThemeMode();
    const mergedTheme = mergeDeep(defaultTheme, customTheme);
    useEffect(() => {
        if (dark) {
            setMode('dark');
            document.documentElement.classList.add('dark');
        }
        else {
            setMode('light');
            document.documentElement.classList.remove('dark');
        }
    }, [dark, setMode]);
    const themeContextValue = useMemo(() => ({
        theme: mergedTheme,
        mode,
        toggleMode,
    }), [mode, toggleMode, mergedTheme]);
    return _jsx(ThemeContext.Provider, { value: themeContextValue, children: children });
};
Flowbite.displayName = 'Flowbite';
export { useTheme, useThemeMode };
