import { Avatar } from "flowbite-react";
import Logo from "jsx:../svg/Logo.svg";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LegalModal, PageWrapper, recordEvent, uploadImageUrl } from "../exports";

export function HomePage() {
  const navigate = useNavigate();
  const [showLegal, setShowLegal] = useState(false);

  const continueButton = {
    color: "primary",
    label: "Create your frame →",
    onClick: () => navigate(uploadImageUrl),
    disabled: false,
  }
  return <PageWrapper buttons={[continueButton]} percentComplete={0}>
    <div className="flex flex-col w-full bg-confetti bg-no-repeat bg-contain bg-left-top pb-32">
      <div className="flex flex-col items-center mt-3 w-full text-3xs text-center text-gray-600">
        <Logo className="w-32 h-32" />
      </div>
      <div className="flex w-full h-full flex-col relative px-6">
        <img src={require("../images/sample-after.png")} className="max-w-[180px] animate-melt absolute top-[30px] right-[50px] z-10" alt="Selfie (before)" />
        <img src={require("../images/sample-before.png")} className="z-0 max-w-[200px] ml-8" alt="Selfie (before)" />
        <img src={require("../images/arrow.png")} className="z-10 absolute max-w-[126px] top-[200px] left-[185px]" />
      </div>
      <div className="flex flex-col items-center text-center text-gray-400 px-4 pt-0 md:mt-10">
        <div className="-space-y-5 md:-space-y-2 text-center">
          <h1>#VoteYes</h1>
          <h2>Selfie Generator</h2>
        </div>
        <h3>
          Join over 10,000+ legends
        </h3>
        <Avatar.Group className="mt-3">
          <Link to="https://www.instagram.com/p/CwtfbPRSPf5/" target="_blank">
            <Avatar img={require("../images/facepile/1.png")} rounded stacked size="md" />
          </Link>
          <Link to="https://www.instagram.com/p/CwlrEDqP_Ym/" target="_blank">
            <Avatar img={require("../images/facepile/2.png")} rounded stacked size="md" />
          </Link>
          <Link to="https://www.instagram.com/p/CwmfuEWoWeM/" target="_blank">
            <Avatar img={require("../images/facepile/3.png")} rounded stacked size="md" />
          </Link>
          <Link to="https://www.instagram.com/thomasamayo/" target="_blank">
            <Avatar img={require("../images/facepile/4.png")} rounded stacked size="md" />
          </Link>
          <Link to="https://www.instagram.com/p/Cw2CciHrDeB/" target="_blank">
            <Avatar img={require("../images/facepile/5.png")} rounded stacked size="md" />
          </Link>
          <Avatar.Counter
            className="text-xs"
            total={999}
          />
        </Avatar.Group>

        <p className="text-sm italic md:mt-4 mt-2">Click below to upload your photo and we'll take care of the rest!</p>

        <p className="mt-5 w-[325px] text-xs -space-y-2 text-gray-400">
          Authorised by Dean Parkin, Australians for Indigenous Constitutional Recognition Ltd, 6/110 Walker St, North Sydney, NSW, Australia
        </p>

        <p className="mt-2 cursor-pointer hover:underline pb-10" onClick={() => setShowLegal(true)}>Legal Info</p>
        <LegalModal show={showLegal} onClose={() => {
          recordEvent("showLegalModal");
          setShowLegal(false);
        }} />
      </div>
    </div>
  </PageWrapper>
}
