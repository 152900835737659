import { Button, Progress } from "flowbite-react";
import ArrowLeftSmall from "jsx:../../svg/ArrowLeftSmall.svg";
import React, { ComponentType, SVGProps } from "react";
import { Link } from "react-router-dom";

interface PageWrapperProps {
  children: React.ReactNode,
  percentComplete: number,
  backButtonUrl?: string,
  buttons?: Button[],
}

interface Button {
  color: string,
  disabled: boolean,
  outlined?: boolean,
  size?: string,
  label: string,
  onClick: () => void,
  leftIcon?: ComponentType<SVGProps<SVGSVGElement>>,
}

export function PageWrapper(props: PageWrapperProps) {
  const hasNav = props.backButtonUrl !== undefined;

  return <div className="flex flex-col h-screen w-full max-w-md max-h-screen overflow-hidden bg-white lg:border border-gray-300">
    <div className="flex flex-col justify-between w-full h-full">
      {hasNav && <div className="fixed top-0 max-w-md w-full flex h-20 p-2 pr-8 z-20 bg-white">
        <div className="flex flex-row w-full h-full justify-center items-center">
          <Link to={props.backButtonUrl}>
            <ArrowLeftSmall className="mr-8 h-10" />
          </Link>
          <div className="flex flex-col h-16 w-full justify-center">
            {props.percentComplete && <Progress progress={props.percentComplete} color="primary" size="lg" />}
          </div>
        </div>
      </div>}

      <div className={`${hasNav ? "mt-20" : ""} flex flex-col flex-grow w-full h-full overflow-y-auto`}>
        {props.children}
      </div>

      {props.buttons && props.buttons.length > 0 &&
        <div className="fixed bottom-0 z-20 bg-white w-full max-w-md p-4 flex flex-col gap-4 border-t-[1px] border-gray-200">
          {props.buttons.map((button, index) => (
            <Button
              key={index}
              color={button.outlined ? "white" : button.color}
              className={button.outlined ? "border-2 border-primary text-primary" : ""}
              disabled={button.disabled}
              fullSized={true}
              pill={true}
              size={button.size ?? "lg"}
              onClick={button.onClick}>
              {button.leftIcon && <button.leftIcon className="mr-2 mt-[2px] h-6" />}
              {button.label}
            </Button>
          ))}
        </div>
      }
    </div>
  </div>
}
