import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { isClient } from '../../helpers/is-client';
import { theme } from '../../theme';
export const ThemeContext = createContext({
    theme,
});
export const ThemeProvider = ({ children, value }) => {
    return _jsx(ThemeContext.Provider, { value: value, children: children });
};
export const useTheme = () => {
    return useContext(ThemeContext);
};
const prefersColorScheme = () => {
    if (!isClient()) {
        return 'light';
    }
    return window.matchMedia?.('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
};
export const useThemeMode = () => {
    const onToggleMode = () => {
        const newMode = mode === 'dark' ? 'light' : 'dark';
        setModeOnBody(newMode);
        setMode(newMode);
    };
    const setModeOnBody = useCallback((mode) => {
        if (!isClient()) {
            return;
        }
        if (mode === 'dark') {
            document.documentElement.classList.add('dark');
        }
        else {
            document.documentElement.classList.remove('dark');
        }
    }, []);
    const { mode: initialMode, toggleMode = onToggleMode } = useContext(ThemeContext);
    const [mode, setMode] = useState('light');
    useEffect(() => {
        if (initialMode) {
            setModeOnBody(initialMode);
            setMode(initialMode);
        }
        else {
            setMode(prefersColorScheme());
        }
    }, [initialMode, setModeOnBody, setMode]);
    return [mode, setMode, toggleMode];
};
