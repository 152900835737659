export const omit = (keys) => (obj) => {
    const result = {};
    Object.keys(obj).forEach((key) => {
        //@ts-expect-error - Somehow TS does not like this.
        if (keys.includes(key)) {
            return;
        }
        //@ts-expect-error - Somehow TS does not like this.
        result[key] = obj[key];
    });
    return result;
};
