import { logEvent } from "firebase/analytics";
import { isProd } from "../exports";
import { analytics } from "../firebase";

export function recordEvent(eventType: string, params?: object) {
  if (isProd) {
    logEvent(analytics, eventType, params);
  } else {
    console.log("[Dev] Analytics Event", eventType, params);
  }
}

export function recordPageView(page_path: string) {
  if (isProd) {
    recordEvent("screen_view", { firebase_screen: page_path });
  } else {
    console.log("[Dev] Analytics Page View", page_path);
  }
}
