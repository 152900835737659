import { jsx as _jsx } from "react/jsx-runtime";
import { twMerge } from 'tailwind-merge';
import { useTheme } from '../../';
import { mergeDeep } from '../../helpers/merge-deep';
import { useSidebarContext } from './SidebarContext';
export const SidebarCTA = ({ children, color = 'info', className, theme: customTheme = {}, ...props }) => {
    const { isCollapsed } = useSidebarContext();
    const theme = mergeDeep(useTheme().theme.sidebar.cta, customTheme);
    return (_jsx("div", { "data-testid": "sidebar-cta", hidden: isCollapsed, className: twMerge(theme.base, theme.color[color], className), ...props, children: children }));
};
SidebarCTA.displayName = 'Sidebar.CTA';
