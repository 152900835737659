import heic2any from "heic2any";
import { createContext } from "react";
import smartcrop from "smartcrop";

class Yes23ProfileImage {
  private canvas: HTMLCanvasElement;
  private uploadedImgData: string = "";
  private generated: boolean = false;

  constructor() {
    this.canvas = document.createElement("canvas");
  }

  clear(): void {
    this.uploadedImgData = "";
    this.canvas = document.createElement("canvas");
  }

  public get hasGenerated(): boolean {
    return this.generated;
  }

  public get hasUploaded(): boolean {
    return this.uploadedImgData?.length > 0;
  }

  public async uploadImage(file: File): Promise<boolean> {
    this.generated = false;
    if (file.name.toLowerCase().includes(".heic")) {
      const blobURL = URL.createObjectURL(file);
      const fetchResult = await fetch(blobURL);
      const blob = await fetchResult.blob();
      const conversionResult: Blob = await heic2any({ blob }) as Blob;
      file = new File([conversionResult], file.name.replace(".heic", ".png"), { type: "image/png" });
    }

    var reader = new FileReader();
    await new Promise<void>((resolve, reject) => {
      reader.onload = () => {
        resolve();
      }
      reader.onerror = () => {
        alert("Can't read your uploaded image");
        reject();
      }
      reader.readAsDataURL(file);
    });
    this.uploadedImgData = reader.result as string;
    return await this.createProfileImage();
  }

  public async uploadImageFromString(data: string): Promise<boolean> {
    this.generated = false;
    this.uploadedImgData = data;
    return await this.createProfileImage();
  }

  public async createProfileImage(): Promise<boolean> {
    const ctx = this.canvas.getContext("2d");
    if (!ctx) {
      alert("Can't create image. Are you using an old browser?");
      throw new Error("Canvas context is null");
    }
    const img = new Image();
    await new Promise<void>((resolve, reject) => {
      img.onload = () => {
        const imgWidth = img.width;
        const imgHeight = img.height;
        const squareSize = Math.min(imgWidth, imgHeight, 1080);
        const fallback = () => {
          this.canvas.width = squareSize;
          this.canvas.height = squareSize;
          const x = (imgWidth - squareSize) / 2;
          const y = 0;
          ctx.drawImage(img, x, y, squareSize, squareSize, 0, 0, squareSize, squareSize);
        }
        if (imgWidth !== imgHeight) {
          // Try using smartcrop
          const self = this;
          smartcrop.crop(img, { width: squareSize, height: squareSize }).then((result) => {
            // if the crop is < 20% of 1080 x 1080, use the fallback.
            if (result.topCrop.width < 216 || result.topCrop.height < 216) {
              console.log("Smartcrop result is too small")
              fallback();
              resolve();
              return;
            }
            const crop = result.topCrop;
            self.canvas.width = crop.width;
            self.canvas.height = crop.height;
            ctx.drawImage(img, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height);
            resolve();
          }).catch((e) => {
            console.log("Can't use smartcrop", e)
            fallback();
            resolve();
          });
        } else {
          const squareSize = Math.min(imgWidth, 1080);
          this.canvas.width = squareSize;
          this.canvas.height = squareSize;
          ctx.drawImage(img, 0, 0, squareSize, squareSize);
          resolve();
        }
      }
      img.onerror = (e) => {
        console.log("Can't read uploaded image", e)
        alert("Can't read your uploaded image");
        reject();
      }
      img.src = this.uploadedImgData;
    });

    const overlay = new Image();
    await new Promise<void>((resolve, reject) => {
      overlay.onload = () => {
        ctx.drawImage(overlay, 0, 0, ctx.canvas.width, ctx.canvas.height);
        resolve();
      }
      overlay.onerror = (e) => {
        console.log("Can't read overlay image", e)
        reject();
      }
      overlay.src = require("../images/frame.png");
    });

    if (this.canvas.width > 1080 || this.canvas.height > 1080) {
      // Resize.
      const resizedCanvas = document.createElement("canvas");
      resizedCanvas.width = 1080;
      resizedCanvas.height = 1080;
      const resizedCtx = resizedCanvas.getContext("2d");
      resizedCtx.drawImage(this.canvas, 0, 0, 1080, 1080);
      this.canvas = resizedCanvas;
    }
    this.generated = true;
    return true;
  }

  public async getBlob(): Promise<Blob> {
    return await new Promise(resolve => this.canvas.toBlob(resolve));
  }

  public getBase64(): string {
    return this.canvas.toDataURL();
  }

  public async createLinktoImage(): Promise<string> {
    const blob = await this.getBlob();
    return window.URL.createObjectURL(blob);
  }
}

export const img = new Yes23ProfileImage();
export const ImgContext = createContext(img);
