import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTheme } from '../../';
import { mergeDeep } from '../../helpers/merge-deep';
export const Radio = forwardRef(({ className, theme: customTheme = {}, ...props }, ref) => {
    const theme = mergeDeep(useTheme().theme.radio, customTheme);
    return _jsx("input", { ref: ref, type: "radio", className: twMerge(theme.root.base, className), ...props });
});
Radio.displayName = 'Radio';
