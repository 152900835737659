import { Button, Select, Spinner } from "flowbite-react";
import Camera from "jsx:../../svg/Camera.svg";
import Close from "jsx:../../svg/Close.svg";
import React, { createRef, useContext, useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { isDesktop } from "react-device-detect";
import { ImgContext, inApp, recordEvent, teriaryColor } from "../../exports";

interface CameraOptions {
  deviceId: string;
  label: string;
}

export function PhotoBooth(props: { show: boolean, onClose: () => void, onTakeImage: () => void }) {
  const img = useContext(ImgContext);
  const cameraRef = createRef<HTMLSelectElement>();
  const videoRef = createRef<HTMLVideoElement>();
  const [countdownClockRunning, setCountdownClockRunning] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [cameras, setCameras] = useState<CameraOptions[]>([]);

  function close() {
    stopVideo();
    props.onClose();
  }

  function stopVideo() {
    const videoEl = videoRef.current;
    if (!videoEl) return;
    const stream: MediaStream = videoEl.srcObject as MediaStream;
    if (!stream) return;
    const tracks = stream.getTracks();
    tracks.forEach((track) => {
      track.stop();
    });
    videoEl.srcObject = null;
  }

  function takePhoto() {
    const video = videoRef.current;
    if (!video) return;
    const canvas = document.createElement("canvas");
    canvas.width = 1080;
    canvas.height = 1080;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    ctx.translate(canvas.width, 0);
    ctx.scale(-1, 1); // Apply mirror.
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const dataUrl = canvas.toDataURL("image/png");
    img.uploadImageFromString(dataUrl);
    recordEvent("tookPhotoBoothPhoto");
    close();
    props.onTakeImage();
  }

  function displayError() {
    if (inApp) {
      alert("It looks like you're inside another app. Please try via imvoting.yes23.com.au");
    } else {
      alert("Can't detect any cameras");
    }
    close();
  }

  function getDevices() {
    return navigator.mediaDevices.enumerateDevices();
  }

  function gotDevices(deviceInfos: MediaDeviceInfo[]) {
    let videoId = 1;
    let camerasFound: CameraOptions[] = [];
    for (const deviceInfo of deviceInfos) {
      if (deviceInfo.kind === "videoinput") {
        // If deviceId is the same as we already have, skip it.
        if (cameras.find((camera) => camera.deviceId === deviceInfo.deviceId)) continue;
        camerasFound.push({ deviceId: deviceInfo.deviceId, label: deviceInfo.label || `Camera ${videoId}` });
        videoId++;
      }
    }
    if (camerasFound.length < 1) {
      displayError();
    } else {
      setCameras(camerasFound);
    }
  }

  async function getStream(): Promise<void> {
    stopVideo();
    const videoSource = cameraRef?.current?.value ?? undefined;
    const constraints = {
      video: {
        width: isDesktop ? { min: 720, max: 720 } : { min: 1080, max: 1080 },
        aspectRatio: 1,
        deviceId: videoSource ? { exact: videoSource } : undefined,
      },
      audio: false
    };
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (!stream) {
        throw new Error("No stream found");
      }
      return gotStream(stream);
    } catch (e) {
      displayError();
      console.error("Error: ", e);
      return;
    }
  }

  function gotStream(stream: MediaStream) {
    const video = videoRef.current;
    video.srcObject = stream;
    video.play();
    const currentDeviceId = stream.getVideoTracks()[0].getSettings().deviceId;
    setSelectedDeviceId(currentDeviceId);
  }

  useEffect(() => {
    setCountdownClockRunning(false);
    if (!props.show) return;
    const video = videoRef.current;
    if (!video) return;
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error("Browser API navigator.mediaDevices.getUserMedia not available");
      displayError();
      return;
    }
    getStream().then(getDevices).then(gotDevices);

    // Close on the escape key
    const closeOnEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        close();
      }
    }
    document.addEventListener("keydown", closeOnEscape);

    return () => {
      stopVideo();
      video.srcObject = null;
      document.removeEventListener("keydown", closeOnEscape);
      setCountdownClockRunning(false);
    }
  }, [props.show]);

  return <div className={`fixed flex flex-col py-4 px-5 top-0 left-0 w-screen h-screen bg-black !z-50 ${props.show ? "" : "hidden"}`}>
    <div className="h-10 flex flex-row flex-shrink justify-end text-white">
      <Close onClick={() => close()} className="w-8 h-8 cursor-pointer" />
    </div>
    <div className="flex flex-col items-center h-full w-full overflow-y-auto">
      <div className="flex flex-col justify-center items-center my-10 relative aspect-square w-full max-w-[450px] max-h-[450px] overflow-hidden">
        {countdownClockRunning && <div className="z-50">
          <CountdownCircleTimer
            isPlaying={countdownClockRunning}
            duration={3}
            colors={teriaryColor}
            trailColor={"#fff"}
            strokeWidth={20}
            onComplete={() => {
              setCountdownClockRunning(false);
              takePhoto();
            }}
          >
            {({ remainingTime }) => <div className="text-4xl text-white">{remainingTime}</div>}
          </CountdownCircleTimer>
        </div>}
        <img src={require("../../images/frame.png")} alt="photo booth" className="z-40 absolute top-0 left-0 w-full h-full rounded-2xl" />
        <video autoPlay={true} playsInline={true} muted={true} ref={videoRef} className="absolute top-[2px] left-0 h-full w-full z-30 aspect-square rounded-3xl" >Video stream not available.</video>
        <div className="absolute top-[1px] left-0 flex flex-col justify-center items-center w-full h-full bg-yellow-100 rounded-3xl">
          <Spinner size="xl" className="z-50" />
        </div>
      </div>

      {cameras.length > 1 && <Select className="w-full max-w-[450px] mb-5 text-black" ref={cameraRef}
        value={selectedDeviceId} onChange={() => {
          recordEvent("changeCamera");
          getStream();
        }}>
        {cameras.map((camera) => <option key={camera.deviceId} value={camera.deviceId}>{camera.label}</option>)}
      </Select>}

      <Button pill fullSized={true} color="primaryAlt" className="max-w-[450px]" onClick={() => {
        recordEvent("startPhotoBoothCountdown");
        setCountdownClockRunning(true);
      }} >
        <Camera className="mr-2 h-6" />
        Take Photo!
      </Button>
    </div>
  </div >
}
